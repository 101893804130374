import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// Axios
import axios from './axios'
Vue.prototype.$axios = axios

Vue.config.productionTip = false

// Mixins
import './mixins/mixins'

// Moment
import moment from 'moment'
Vue.prototype.$moment = moment

new Vue({
	vuetify,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
