import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// =============================================================================
// AUTH CHECK
// =============================================================================
function requireAuth(to, from, next) {
	// API to check token
	router.app.$axios.post(`/checkTokenValidity`)
		.then(() => {
			// Proceed to route
			next();
		})
		.catch(()  => {
			// Redirect to login
			next('/login');
		})
}
// =============================================================================

const routes = [
	{
		// =============================================================================
		// MAIN LAYOUT ROUTES
		// =============================================================================
		path: '',
		component: () => import('../layouts/Main.vue'),
		beforeEnter: requireAuth,
		children: [
			{
				path: '/',
				name: 'home',
				component: () => import('../views/Home.vue'),
			},
			{
				path: '/leads',
				name: 'leads',
				component: () => import('../views/modules/leads/Leads.vue'),
			},
			{
				path: '/quotations',
				name: 'quotations',
				component: () => import('../views/modules/quotations/Quotations.vue'),
			},
			{
				path: '/orders',
				name: 'orders',
				component: () => import('../views/modules/orders/Orders.vue'),
			},
			{
				path: '/customers',
				name: 'customers',
				component: () => import('../views/modules/customers/Customers.vue'),
			},
			{
				path: '/products',
				name: 'products',
				component: () => import('../views/modules/products/Products.vue'),
			},
			{
				path: '/services',
				name: 'services',
				component: () => import('../views/modules/services/Services.vue'),
			},
			{
				path: '/contacts',
				name: 'contacts',
				component: () => import('../views/modules/contacts/Contacts.vue'),
			},
			{
				path: '/calls',
				name: 'calls',
				component: () => import('../views/modules/calls/Calls.vue'),
			},
			{
				path: '/meetings',
				name: 'meetings',
				component: () => import('../views/modules/meetings/Meetings.vue'),
			},
			{
				path: '/emails',
				name: 'emails',
				component: () => import('../views/modules/emails/Emails.vue'),
			},
			{
				path: '/tasks',
				name: 'tasks',
				component: () => import('../views/modules/tasks/Tasks.vue'),
			},
			// Reports
			{
				path: '/report-leads-converted',
				name: 'report-leads-converted',
				component: () => import('../views/modules/reports/LeadsConverted.vue'),
			},
			{
				path: '/report-company-revenue',
				name: 'report-company-revenue',
				component: () => import('../views/modules/reports/CompanyRevenue.vue'),
			},
			{
				path: '/report-order-pl',
				name: 'report-order-pl',
				component: () => import('../views/modules/reports/OrderPL.vue'),
			},
			{
				path: '/help',
				name: 'help',
				component: () => import('../views/WIP.vue'),
			},
		],
	},
	{
		// =============================================================================
		// FULL PAGE LAYOUTS
		// =============================================================================
		path: '',
		component: () => import('../layouts/FullPage.vue'),
		children: [
			{
				path: '/login',
				name: 'login',
				component: () => import('../views/Login.vue')
			},
		]
	},
	// Redirect to 404 page, if no match found
	{
		path: '*',
		redirect: '/'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
