// axios
import axios from 'axios'

let http = axios.create({
	baseURL: 'https://fadesk.com/api'
});

if(localStorage.getItem('fsk_token')){
	http.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('fsk_token');
}
http.defaults.headers.common['Accept'] = 'application/json';

export default http;
