/*=========================================================================================
File Name: mutations.js
Description: Vuex Store - mutations
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


const mutations = {
	
	// ////////////////////////////////////////////
	// UI
	// ////////////////////////////////////////////
	
	UPDATE_WINDOW_WIDTH(state, width)  { state.windowWidth       = width },


	// ////////////////////////////////////////////
    // CUSTOM
    // ////////////////////////////////////////////
}

export default mutations

