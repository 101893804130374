import Vue from 'vue'

Vue.mixin({
	methods: {
		capitalizeFirstLetter: function (s) {
			if(typeof s !== 'string') {
				return ''
			}
			else {
				return s.split(' ').map(w => w.substring(0, 1).toUpperCase() + w.substring(1)).join(' ')
			}
		},
		convertToSlug: function(s) {
			if(typeof s !== 'string') {
				return ''
			}
			else {
				// eslint-disable-next-line no-useless-escape
				return s.toString().trim().toLowerCase().replace(/\s+/g, "-").replace(/[^\w\-]+/g, "").replace(/\-\-+/g, "-").replace(/^-+/, "").replace(/-+$/, "")
			}
		},
		numberWithCommas: function (x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		formatTime: function (time24) {
			var ts = time24;
			var H = +ts.substr(0, 2);
			var h = (H % 12) || 12;
			h = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
			var ampm = H < 12 ? " AM" : " PM";
			ts = h + ts.substr(2, 3) + ampm;
			return ts;
		},
		getQueryVariable: function (variable) {
			let query = window.location.search.substring(1);
			let vars = query.split("&");
			for (let i = 0; i < vars.length; i++) {
				let pair = vars[i].split("=");
				if (pair[0] == variable) {
					return pair[1];
				}
			}
			return (false);
		}
	}
});
